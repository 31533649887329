import React from 'react'
import { render, hydrate } from 'react-dom'
import App from './app'
import { setupGoogleAnalytics } from '@envato/google-analytics'

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

setupGoogleAnalytics()

window.addEventListener('load', () => {
  const hash = window?.location?.hash

  if (hash && document.querySelector(hash)) {
    setTimeout(() => document.querySelector(hash).scrollIntoView(), 0)
  }

  // Need to inject the cookiebot script tag at runtime ensuring the userAgent isn't ReactSnap,
  // otherwise react-snap will load and execute it (and load and execute its geolocation-dependent
  // dependencies) at build time
  // This is done here - and not directly in the markup - for performance gains relating to
  // Core Web Vitals. It is a similar approach used by Google Analytics & Google tag
  // Manager.
  if (process.env.NODE_ENV === 'production') {
    const cssText = `
    /* Cookiebot CMP branding */
    a#CybotCookiebotDialogPoweredbyCybot,
    div#CybotCookiebotDialogPoweredByText {
      display: none !important;
    }

    /* Layout */
    #CybotCookiebotDialogDetailFooter {
      display: none !important;
    }

    /* Cookie detail list */
    .CybotCookiebotDialogDetailBodyContentCookieTypeTableContainer {
      display: none !important;
    }

    /* Cookies categories tabs */
    #CybotCookiebotDialogDetailBodyContentCookieContainerUnclassified {
      display: none !important;
    }

    /* Cookie category toggle */
    .CybotCookiebotDialogDetailBodyContentCookieContainerButton::before {
      display: none !important;
    }

    /* Cookie count */
    .CybotCookiebotDialogDetailBulkConsentCount {
      display: none !important;
    }
    `
    const cbStyle = document.createElement('style')
    cbStyle.appendChild(document.createTextNode(cssText))

    document.head.appendChild(cbStyle)

    if (navigator && navigator.userAgent !== 'ReactSnap') {
      const cbScript = document.createElement('script')

      cbScript.async = 1
      cbScript.id = 'Cookiebot'
      cbScript.setAttribute('data-cbid', 'd10f7659-aa82-4007-9cf1-54a9496002bf')
      cbScript.setAttribute('data-georegions', "{'region':'US','cbid':'d9683f70-895f-4427-97dc-f1087cddf9d8'}")
      cbScript.type = 'text/javascript'
      cbScript.src = 'https://consent.cookiebot.com/uc.js'

      document.body.appendChild(cbScript)
    }
  }
})
