import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Page from '../layouts/page'
import SomethingWentWrong from '../components/something_went_wrong/something_went_wrong'
import { createI18nPages } from '../i18n'
import { languages, defaultLanguage } from '../i18n/languages'
import PageIdContext from '../contexts/page_id_context'
import AnalyticsPageviewTracker from '../components/analytics_pageview_tracker/analytics_pageview_tracker'
import { Loading } from '@envato/ui'
import { ErrorBoundary } from 'react-error-boundary'

const commonDescription = {
  [languages.en]:
    "Explore Envato's vast collection of premium digital assets for creative projects. Find top-quality stock, templates, themes to elevate your design work!"
}

const pages = [
  {
    pageId: 'home',
    title: { [languages.en]: 'Envato: Premium Digital Assets for Creatives - Templates and more' },
    description: commonDescription,
    Component: React.lazy(() => import('../pages/home'))
  },
  // subpages are listed in alphabetical order
  {
    pageId: 'affiliatesPayout',
    title: { [languages.en]: 'Envato - Affiliates FAQs' },
    description: commonDescription,
    Component: React.lazy(() => import('../pages/affiliates/envato-affiliates-payout-process'))
  },
  {
    pageId: 'affiliatesHowToCreateLink',
    title: { [languages.en]: 'Envato - Affiliates FAQs' },
    description: commonDescription,
    Component: React.lazy(() => import('../pages/affiliates/how-do-i-create-a-referral-link-in-impact'))
  },
  {
    pageId: 'affiliatesHowToImpact',
    title: { [languages.en]: 'Envato - Affiliates FAQs' },
    description: commonDescription,
    Component: React.lazy(() => import('../pages/affiliates/how-do-i-find-ads-in-impact'))
  },
  {
    pageId: 'affiliatesHowToGetPaid',
    title: { [languages.en]: 'Envato - Affiliates FAQs' },
    description: commonDescription,
    Component: React.lazy(() => import('../pages/affiliates/how-do-i-get-paid'))
  },
  {
    pageId: 'archivedPrivacyPolicy2019_04_10',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2019-04-10' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2019-04-10'))
  },
  {
    pageId: 'archivedPrivacyPolicy2021_10_11',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2021-10-11' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2021-10-11'))
  },
  {
    pageId: 'archivedPrivacyPolicy2022_03_15',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2022-03-15' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2022-03-15'))
  },
  {
    pageId: 'archivedPrivacyPolicy2022_08_04',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2022-08-04' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2022-08-04'))
  },
  {
    pageId: 'archivedPrivacyPolicy2022_09_06',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2022-09-06' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2022-09-06'))
  },
  {
    pageId: 'archivedPrivacyPolicy2024_04_08',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2024-04-08' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2024-04-08'))
  },
  {
    pageId: 'archivedPrivacyPolicy2025_03_11',
    title: { [languages.en]: 'Envato Archive - Privacy Policy 2025-03-11' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy/archive/2025-03-11'))
  },
  {
    pageId: 'archivedWhistleblowerPolicy2019_12_24',
    title: { [languages.en]: 'Envato Archive - Whistleblower Policy 2019-12-24' },
    description: {
      [languages.en]:
        "Our Australian Whistleblower Policy sets out our commitment to detecting and addressing misconduct in relation to Envato's Australian operations."
    },
    Component: React.lazy(() => import('../pages/whistleblower-policy/archive/2019-12-24'))
  },
  {
    pageId: 'archivedWhistleblowerPolicy2022_06_30',
    title: { [languages.en]: 'Envato Archive - Whistleblower Policy 2022-06-30' },
    description: {
      [languages.en]:
        "Our Australian Whistleblower Policy sets out our commitment to detecting and addressing misconduct in relation to Envato's Australian operations."
    },
    Component: React.lazy(() => import('../pages/whistleblower-policy/archive/2022-06-30'))
  },
  {
    pageId: 'contact',
    title: { [languages.en]: 'Contact Envato Support | Find an Envato Service' },
    description: {
      [languages.en]:
        'Find help centre resources and how to get in touch with our Envato support teams for the Envato service you use.'
    },
    Component: React.lazy(() => import('../pages/contact'))
  },
  {
    pageId: 'cookies',
    title: { [languages.en]: 'Cookie Policy - Envato' },
    description: { [languages.en]: 'Our cookie policy explains the tracking technologies we use on our sites.' },
    Component: React.lazy(() => import('../pages/cookies'))
  },
  {
    pageId: 'privacy',
    title: { [languages.en]: 'Privacy Policy - Envato' },
    description: {
      [languages.en]:
        'Our privacy policy sets out how we handle your personal information if you’re an Envato user or visitor to our sites.'
    },
    Component: React.lazy(() => import('../pages/privacy'))
  },
  {
    pageId: 'products',
    title: { [languages.en]: 'Envato Products - Get any creative project done with Envato' },
    description: {
      [languages.en]:
        'From our leading creative marketplaces, unlimited subscription service, DIY makers and more, Envato has everything you need to get creative projects done.'
    },
    Component: React.lazy(() => import('../pages/products'))
  },
  {
    pageId: 'sitemap',
    title: { [languages.en]: 'Sitemap - Envato' },
    description: { [languages.en]: '' },
    Component: React.lazy(() => import('../pages/sitemap'))
  },
  {
    pageId: 'aboutCompany',
    title: { [languages.en]: 'About Envato - Company Overview' },
    description: {
      [languages.en]:
        'Envato is a world-leading online community for creative assets, tools and talent. Discover how we help people bring their creative ideas to life.'
    },
    Component: React.lazy(() => import('../pages/about/company'))
  },
  {
    pageId: 'aboutPurpose',
    title: { [languages.en]: 'Envato’s Mission - Our Purpose' },
    description: {
      [languages.en]:
        'At Envato we are committed to balancing purpose and profit. Discover how our values drive our creative mission.'
    },
    Component: React.lazy(() => import('../pages/about/purpose'))
  },
  {
    pageId: 'aboutCareers',
    title: {
      [languages.en]: 'Careers at Envato - Browse all Jobs',
      [languages.es]: 'Profesiones en Envato - Ve nuestras vacantes'
    },
    description: {
      [languages.en]:
        'Come and find your place within our talented, global community of clever and passionate people who strive for purpose and profit. View all our open roles.',
      [languages.es]:
        'Encuentra tu lugar en nuestra talentosa comunidad global de personas inteligentes y apasionadas que se esfuerzan por un propósito y para generar ganancias. Ve todas nuestras vacantes.'
    },
    Component: React.lazy(() => import('../pages/about/careers/index'))
  },
  {
    pageId: 'aboutSustainability',
    title: { [languages.en]: 'About Envato - Sustainability' },
    description: {
      [languages.en]:
        'We are a registered B-Corp, proudly carbon-neutral and determined to continuously go above and beyond to be kind to our planet.'
    },
    Component: React.lazy(() => import('../pages/about/sustainability'))
  },
  {
    pageId: 'aboutCareersGuadalajara',
    title: {
      [languages.en]: 'Careers at Envato - Browse Guadalajara Jobs',
      [languages.es]: 'Profesiones en Envato - Ve nuestras vacantes en Guadalajara'
    },
    description: {
      [languages.en]:
        'Come and find your place within our talented, global community of clever and passionate people who strive for purpose and profit. View our open roles in Guadalajara.',
      [languages.es]:
        'Encuentra tu lugar en nuestra talentosa comunidad global de personas inteligentes y apasionadas que se esfuerzan por un propósito y para generar ganancias. Ve todas nuestras vacantes en Guadalajara.'
    },
    Component: React.lazy(() => import('../pages/about/careers/guadalajara'))
  },
  {
    pageId: 'aboutCareersMelbourne',
    title: {
      [languages.en]: 'Careers at Envato - Browse Australia & New Zealand Jobs',
      [languages.es]: 'Carreras en Envato - Búsqued a de trabajo en Australia y Nueva Zelanda'
    },
    description: {
      [languages.en]:
        'Come and find your place within our talented, global community of clever and passionate people who strive for purpose and profit. View our open roles in Australia and New Zealand.',
      [languages.es]:
        'Ven y forma parte de nuestra talentosa comunidad global, de personas inteligentes y apasionadas que se esfuerzan por lograr un propósito y obtener ganancias. Conoce nuestras vacantes en Australia y Nueva Zelanda.'
    },
    Component: React.lazy(() => import('../pages/about/careers/melbourne'))
  },
  {
    pageId: 'affiliatesPrograms',
    title: { [languages.en]: 'Envato Affiliates - Programs' },
    description: {
      [languages.en]:
        'Join our affiliate community and earn money through referring individual digital assets, our unlimited creative subscription, or our DIY design tool.'
    },
    Component: React.lazy(() => import('../pages/affiliates/programs'))
  },
  {
    pageId: 'affiliatesFAQs',
    title: { [languages.en]: 'Envato Affiliates - FAQs' },
    description: {
      [languages.en]:
        'Join our affiliate community and earn money through referring individual digital assets, our unlimited creative subscription, or our DIY design tool.'
    },
    Component: React.lazy(() => import('../pages/affiliates/faqs'))
  },
  {
    pageId: 'whistleblowerPolicy',
    title: { [languages.en]: 'Australian Whistleblower Policy - Envato' },
    description: {
      [languages.en]:
        "Our Australian Whistleblower Policy sets out our commitment to detecting and addressing misconduct in relation to Envato's Australian operations."
    },
    Component: React.lazy(() => import('../pages/whistleblower-policy'))
  },
  {
    pageId: 'antiBribery',
    title: { [languages.en]: 'Anti-Bribery and Corruption Policy - Envato' },
    description: {
      [languages.en]:
        "Our Anti-bribery and Corruption policy statement sets out Envato's zero tolerance approach to bribery and corruption."
    },
    Component: React.lazy(() => import('../pages/anti-bribery'))
  },
  {
    pageId: 'slaveryPolicy',
    title: { [languages.en]: 'Modern Slavery Policy - Envato' },
    description: {
      [languages.en]:
        'Our Modern Slavery Policy sets out our commitment to preventing modern slavery in our supply chains.'
    },
    Component: React.lazy(() => import('../pages/slavery-policy'))
  },
  {
    pageId: 'dsaPolicy',
    title: { [languages.en]: 'Digital Services Act (EU) - Envato' },
    description: {
      [languages.en]:
        'This page is for information about how Envato complies with the European Union’s Digital Services Act (DSA), which is a law intended to prevent illegal and harmful online activities.'
    },
    Component: React.lazy(() => import('../pages/digital-services-act-eu'))
  }
]

const PageRoutes = () => (
  <Switch>
    {createI18nPages(pages).map(
      ({ path, title, description, Component, pageId, lang = defaultLanguage, alternate = [] }) => (
        <Route exact path={path} key={path}>
          <AnalyticsPageviewTracker title={title} />
          <PageIdContext.Provider value={pageId}>
            <Page title={title} description={description} path={path} lang={lang} alternate={alternate}>
              <ErrorBoundary fallback={<SomethingWentWrong type='error' />}>
                <React.Suspense fallback={<Loading />}>
                  <Component />
                </React.Suspense>
              </ErrorBoundary>
            </Page>
          </PageIdContext.Provider>
        </Route>
      )
    )}
    <Route>
      <SomethingWentWrong type='notFound' />
    </Route>
  </Switch>
)

export default PageRoutes
